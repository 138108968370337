import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProfileTabsLink from './ProfileTabsLink';
import Orders from './Orders';
import Dashboard from './Dashboard';
import Bonuses from './Bonuses';
import Address from './Address';
import OrderProductDetail from './OrderProductDetail';
import OrderHistory from './OrderHistory';
import DashboardEdit from './DashboardEdit';
import AboutUs from './AboutUs';
import Notifications from './Notifications';
import Support from './Support';
import Reviews from './Reviews';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ProfileTabs = observer(() => {
    const {app} = useContext(Context);
  return (
    <div className="profile-grid">
        <ProfileTabsLink/>
        <div className="profile-right">
            <div className="dashboard-right-sidebar">
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path='dashboard' element={<Dashboard/>}/>
                    <Route path='dashboard/edit' element={<DashboardEdit/>}/>
                    <Route path='orders' element={<Orders/>}/>
                    <Route path='bonuses' element={<Bonuses/>}/>
                    <Route path='addresses' element={<Address/>}/>
                    <Route path='orders-history' element={<OrderHistory/>}/>
                    <Route path='orders/:id' element={<OrderProductDetail/>}/>
                    <Route path='about_us' element={<AboutUs/>}/>
                    <Route path='notifications' element={<Notifications/>}/>
                    <Route path='support' element={<Support/>}/>
                    {app.reviewEnabled ? <Route path='reviews' element={<Reviews/>}/> : <></>}
                </Routes>
            </div>
        </div>
    </div>
  )
})

export default ProfileTabs