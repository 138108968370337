import React, { useContext, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import ReviewsServices from '../../services/general/reviewsServices';
import { toast } from 'react-toastify';

const ProductReviewCard = observer(({
    card
}) => {
    const {app} = useContext(Context);
    const [selectedRating, setSelectedRating] = useState(card.points);

    const [show, setShow] = useState();
    const [feedback, setFeedback] = useState('')

    const stars = [1, 2, 3, 4, 5];

    const handleSubmit = async () => {
        const res = await ReviewsServices.reply(card.id, {text: feedback});

        if(res.statusCode === 200){
            toast.success(app.localizationsItems?.review_moderation);
            setFeedback('');
            setShow(false)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='product-reviews-item'>
        <div className='product-reviews-item-flex'>
            <h4>{card.user?.name}</h4>
            <ul className="rating">
                {stars.map((star, index) => 
                    <li key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                            fill={selectedRating >= star ? '#ffb321' : 'none'}
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                            className="feather feather-star">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
                        </svg>
                    </li>
                )}
            </ul>
        </div>
        <div className='product-reviews-item-date'>{card.created_at}</div>
        <hr/>
        {card.attachments?.length >= 1 ?
            <div className='product-reviews-item-images'>
                {card.attachments.map((image, index) =>
                    <img src={image} alt="" key={index}/>
                )}
            </div>
            : <></>
        }
        <p>{card.text}</p>
        <div className='reply-button' onClick={() =>setShow(true)}>{app.localizationsItems?.answer}</div>
        {show && 
            <div className='review-reply'>
                <div className='review-reply-top'>{app.localizationsItems?.review_answer}</div>
                <div className='review-reply-user'>{card.user?.name}</div>
                <textarea className='form-control' placeholder='Текст комментария' value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
                <div className='list-buttons'>
                    <div className='btn btn-sm' onClick={() =>setShow(false)}>{app.localizationsItems?.cancel}</div>
                    <div className='main-btn btn-sm' onClick={handleSubmit}>{app.localizationsItems?.send}</div>
                </div>
            </div>
        }
        {card.admin_replies?.map((it, index) =>
            <div key={index} className='review-reply'>
                <div className='review-reply-top'>{app.localizationsItems?.review_answer} <span>{it.created_at}</span></div>
                <div className='review-reply-user'>{it.user?.name}</div>
                <p>{it.text}</p>
            </div>
        )}
    </div>
  )
})

export default ProductReviewCard